<template>
  <VSkeletonLoader
    grid-list-lg
    fill-height
    fluid
    type="table-heading,card"
    :loading="activity.isFirstLoad"
  >
    <VRow>
      <SaveHeader
        :owner="this"
        :mode="mode"
      />
      <VCol style="flex: 1 1 auto">
        <VRow
          row
          wrap
        >
          <VCol
            xs12
            md6
          >
            <VCard>
              <VCardTitle>Details</VCardTitle>
              <VCardText>
                <Autocomplete
                  v-model="form.program_id"
                  item-value="id"
                  item-text="title"
                  :items="options.programs"
                  :placeholder="`Select ${featureName('Program').singularize()}`"
                />
                <template v-if="canSelectEntry">
                  <h2 class="title">
                    Select an existing event
                  </h2>
                  <Autocomplete
                    v-model="form.calendar_entry_id"
                    clearable
                    item-value="id"
                    item-text="date"
                    :disabled="!form.program_id"
                    :items="programInstances"
                    :placeholder="`Select ${featureName('Program').singularize()} Day`"
                    :error="$v.form.calendar_entry_id.$error"
                    @input="updateCalendarFromEntry"
                  />
                </template>
                <VRow
                  v-show="canCreateEntry"
                  row
                  wrap
                >
                  <VCol xs12>
                    <h2 class="title">
                      {{ hadNullEntryStartDate ? 'Or enter a date' : 'Enter the date and time' }}
                      <VBtn
                        v-if="formHasEntryStartDate && !formHasId"
                        small
                        @click="onClearInstanceDate"
                      >
                        <VIcon>clear</VIcon>Clear dates
                      </VBtn>
                    </h2>
                  </VCol>
                  <template v-if="programHasCalendars">
                    <VCol xs12>
                      <Autocomplete
                        v-model="form.calendar_id"
                        clearable
                        item-value="id"
                        item-text="title"
                        :disabled="!form.program_id"
                        :items="programCalendars"
                        :placeholder="`Select ${featureName('Program')} Calendar`"
                        :error-messages="$v.form.calendar_id.$error ? ['Select a calendar'] : []"
                        :error="$v.form.calendar_id.$error"
                        @input="setCalendar"
                        @blur="$v.$touch()"
                      />
                    </VCol>
                    <template v-if="formHasCalendar">
                      <VCol
                        xs12
                        md6
                      >
                        <VMenu
                          v-model="menu.startDateMenu"
                          lazy
                          offset-y
                          ref="startDateMenu"
                          transition="scale-transition"
                          min-width="290px"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="form.calendar_entry.start_date"
                        >
                          <TextField
                            slot="activator"
                            v-model="form.calendar_entry.start_date"
                            label="Start Date"
                            prepend-icon="event"
                            readonly
                            clearable
                            :error="$v.form.calendar_entry.start_date.$error"
                            @input="$v.form.calendar_entry.start_date.$touch()"
                            @blur="$v.$touch()"
                            :error-messages="$v.form.calendar_entry.start_date.$error && !form.calendar_entry.start_date ? ['Select a date'] : []"
                          />
                          <VDatePicker
                            v-model="form.calendar_entry.start_date"
                            scrollable
                            @input="onSetStartDate"
                            @blur="$v.$touch()"
                            :error="$v.form.calendar_entry.$error"
                          />
                        </VMenu>
                      </VCol>
                      <VCol
                        xs12
                        md6
                      >
                        <TextField
                          slot="activator"
                          v-model="form.calendar_entry.start_time"
                          type="time"
                          label="Enter Time"
                          prepend-icon="access_time"
                          clearable
                          :error="$v.form.calendar_entry.start_time.$error"
                          @input="$v.form.calendar_entry.start_time.$touch()"
                          @blur="$v.$touch()"
                          :error-messages="$v.form.calendar_entry.start_time.$error && !form.calendar_entry.start_time ? ['Select a start time'] : []"
                        />
                      </VCol>
                      <VCol
                        xs12
                        md6
                      >
                        <VMenu
                          v-model="menu.endDateMenu"
                          lazy
                          offset-y
                          ref="endDateMenu"
                          transition="scale-transition"
                          min-width="290px"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="form.calendar_entry.end_date"
                        >
                          <TextField
                            slot="activator"
                            v-model="form.calendar_entry.end_date"
                            label="End Date"
                            prepend-icon="event"
                            readonly
                          />
                          <VDatePicker
                            v-model="form.calendar_entry.end_date"
                            scrollable
                            @input="$refs.endDateMenu.save(form.calendar_entry.end_date)"
                          />
                        </VMenu>
                      </VCol>
                      <VCol
                        xs12
                        md6
                      >
                        <TextField
                          slot="activator"
                          v-model="form.calendar_entry.end_time"
                          type="time"
                          label="Enter Time"
                          prepend-icon="access_time"
                        />
                      </VCol>
                    </template>
                  </template>
                </VRow>
                <VCol
                  xs12
                  v-if="formHasProgam && !programHasCalendars"
                >
                  <VAlert
                    :value="true"
                    type="warning"
                  >
                    There's no calendar for this {{ featureName('program') }}. Add one below
                  </VAlert>
                  <QuickSaveCalendar
                    @saved="addCalendar"
                    :user-form="{
                      title: selectedProgram.title,
                      entity_id: form.program_id,
                      entity_type: 'App\\Models\\Program',
                      members: form.members || []
                    }"
                  />
                </VCol>
              </VCardText>
            </VCard>
          </VCol>
          <VCol
            xs12
            md6
          >
            <VCard v-show="!$isUserStudent">
              <VCardTitle>
                <VRow
                  row
                  wrap
                  justify-space-between
                >
                  <VCol xs12>
                    <h4>{{ featureName('Students', 'pluralize') }}</h4>
                  </VCol>
                  <VCol
                    xs12
                    lg6
                  >
                    <VBtn
                      style="box-shadow: 0 0 10px 0 #E8E8E8;"
                      class="vertical-padding"
                      text:block="$vuetify.breakpoint.mdAndDown"
                      @click="onToggleMembersDialog"
                      :disabled="!formHasProgam"
                    >
                      <VIcon>edit</VIcon>
                      Edit {{ featureName('Students', 'pluralize') }} List
                    </VBtn>
                  </VCol>
                  <VCol
                    xs12
                    lg6
                  >
                    <VBtn
                      class="vertical-padding dashed"
                      :disabled="studentCount === 0"
                    >
                      <VIcon
                        color="primary"
                        v-if="allStudentsPresent"
                      >
                        check_box
                      </VIcon>
                      <VIcon
                        color="primary"
                        v-else-if="presentCount"
                      >
                        indeterminate_check_box
                      </VIcon>
                      <VIcon v-else>
                        check_box_outline_blank
                      </VIcon>Mark All Present
                    </VBtn>
                  </VCol>
                </VRow>
              </VCardTitle>
              <VCardText>
                <VExpansionPanels>
                  <VExpansionPanel class="primary elevation-0">
                    <VExpansionPanelContent :disabled="!formHasProgam">
                      <VIcon slot="actions">
                        expand_more
                      </VIcon>
                      <div slot="header">
                        Add a new {{ featureName('Students', 'singularize') }}?
                      </div>
                      <QuickSaveUser
                        @saved="addMember"
                        :add-exsiting-to-team="true"
                        :programs="options.programs.filter(p => p.id === form.program_id)"
                      />
                    </VExpansionPanelContent>
                  </VExpansionPanel>
                </VExpansionPanels>
                <MembersList
                  v-if="form.students && form.students.length"
                  :label="`Filter ${featureName('Students', 'pluralize').toLowerCase()}`"
                  :users="form.students"
                  @removeMember="removeMember"
                >
                  <VRow
                    slot="info"
                    align-center
                    justify-end
                    row
                  >
                    <VCol>Mark {{ featureName('student').toLowerCase() }} attendance below</VCol>
                    <VCol class="text-right">
                      <span :class="presentCount ? 'text-primary': ''">{{ presentCount }}</span>
                      /{{ studentCount }}
                    </VCol>
                  </VRow>
                  <VListItem
                    slot="member"
                    slot-scope="m"
                    :key="m.member.name"
                    class="flex xs12 lg6"
                  >
                    <VListItemAction>
                      <VCheckbox
                        v-model="m.member.is_present"
                        color="primary"
                        icon
                        text
                      />
                    </VListItemAction>
                    <VListItemAvatar>
                      <img :src="m.member.photo_url">
                    </VListItemAvatar>
                    <VListItemContent>
                      <VListItemTitle>
                        <p>{{ m.member.name }}</p>
                      </VListItemTitle>
                    </VListItemContent>
                    <VListItemAction>
                      <VBtn
                        icon
                        text
                        @click="removeMember(m.member)"
                      >
                        <VIcon>clear</VIcon>
                      </VBtn>
                    </VListItemAction>
                  </VListItem>
                </MembersList>
                <p v-else>
                  No {{ featureName('students', 'pluralize') }} have been added to this attendance submission
                </p>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
        <Dialog
          :hide-overlay="true"
          :is-dialog-open="dialogs.members"
          :title="`Add ${featureName('Students', 'pluralize')}`"
          @toggle:dialog="onToggleMembersDialog"
        >
          <MembersForm
            ref="addMembers"
            type="students"
            mode="multiple"
            :existing="form.students"
            :is-group="true"
            :custom-filter="{programStudents: this.selectedProgram.id}"
            @addMember="addMember"
            @removeMember="removeMember"
            @saveMembers="dialogs.members = false"
          />
        </Dialog>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import utils from "@nitm/js-api-client-base/dist/store/utils";
import FormMixin from "@/mixins/Form";
import HasMembers from "@/mixins/HasMembers";
import MembersForm from "@/components/Elements/Forms/MembersForm";
import MembersList from "@/components/Elements/Data/MembersList";
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import QuickSaveUser from "@/components/Users/QuickSave";
import QuickSaveCalendar from "@/components/Calendar/QuickSaveLocal";
import { mapActions } from "vuex";
import {
  required,
  requiredIf,
  minLength,
  between,
  integer,
} from "vuelidate/lib/validators";
export default {
  name: "AttendancesSave",
  components: {
    MembersForm,
    MembersList,
    SaveHeader,
    QuickSaveUser,
    QuickSaveCalendar,
  },
  mixins: [FormMixin, HasMembers],
  data() {
    return {
      menu: {
        startDateMenu: false,
        endDateMenu: false,
        startTimeMenu: false,
        endTimeMenu: false,
      },
      modelType: "Attendance",
      allStudentsPresent: false,
      hadNullEntryStartDate: true,
      options: {
        programs: [],
      },
      defaultForm: {
        program_id: null,
        calendar_id: null,
        calendar_entry_id: null,
        students: [],
        calendar_entry: {
          start_date: null,
          start_time: null,
          end_date: null,
          end_time: null,
        },
      },
    };
  },
  validations: {
    form: {
      program_id: {
        required,
      },
      calendar_id: {
        integer,
      },
      calendar_entry_id: {
        required: requiredIf(function () {
          return this.hasNewCalendarEntry && !this.formHasEntryStartDate;
        }),
      },
      calendar_entry: {
        start_date: {
          required: requiredIf(function () {
            return this.hasExistingCalendarEntryId === false;
          }),
        },
        start_time: {
          required: requiredIf(function () {
            return this.hasExistingCalendarEntryId === false;
          }),
        },
      },
    },
  },
  computed: {
    formHasProgam() {
      return parseInt(this.form.program_id || 0) > 0;
    },
    formHasCalendar() {
      return parseInt(this.form.calendar_id || 0) > 0;
    },
    formHasCalendarEntryId() {
      return this.form.calendar_entry_id > 0;
    },
    formHasCalendarEntry() {
      return this.form.calendar_entry instanceof Object;
    },
    formHasExistingCalendarEntry() {
      return this.formHasCalendarEntry && this.form.calendar_entry.id > 0;
    },
    formHasEntryStartDate() {
      return (
        this.formHasCalendarEntry && this.form.calendar_entry.start_date != null
      );
    },
    canCreateEntry() {
      return (
        this.formHasProgam &&
        !this.formHasCalendarEntryId &&
        this.programHasCalendars
      );
    },
    canSelectEntry() {
      return (
        (this.formHasProgam &&
          this.hadNullEntryStartDate &&
          this.programHasInstances &&
          !this.formHasEntryStartDate) ||
        this.formHasId
      );
    },
    hasNewCalendarEntry() {
      return (
        this.hasExistingCalendarEntry === false ||
        (this.formHasEntryStartDate === true &&
          this.formHasCalendarEntryId === true &&
          parseInt((this.form.calendar_entry || {}).id || 0) == 0)
      );
    },
    hasExistingCalendarEntry() {
      return (
        this.formHasCalendarEntry &&
        parseInt(this.form.calendar_entry.id || 0) > 0
      );
    },
    presentCount() {
      return this.form.students
        ? this.form.students.filter((student) => student.is_present === true)
            .length
        : 0;
    },
    studentCount() {
      return this.form.students ? this.form.students.length : 0;
    },
    programInstances() {
      const program = this.options.programs.find(
        (program) => program.id == this.form.program_id
      );
      return program instanceof Object &&
        program.past_calendar_entries instanceof Array
        ? program.past_calendar_entries.map((entry) => {
            entry.date = this.$timezone.formatDateTime(entry.date);
            return entry;
          })
        : [];
    },
    programHasInstances() {
      return this.programInstances.length > 0;
    },
    programHasCalendars() {
      const program = this.options.programs.find(
        (p) => p.id === this.form.program_id
      );
      return program instanceof Object &&
        program.past_calendar_entries instanceof Array
        ? program.calendars instanceof Array && program.calendars.length > 0
        : false;
    },
    programCalendars() {
      const program = this.options.programs.find(
        (p) => p.id === this.form.program_id
      );
      return program instanceof Object
        ? program.calendars instanceof Array && program.calendars
        : [];
    },
    selectedProgram() {
      return (
        this.options.programs.find((p) => p.id === this.form.program_id) || {}
      );
    },
    selectedCalendar() {
      if (this.selectedProgram instanceof Object) {
        return (
          this.selectedProgram.calendars.find(
            (c) => c.id === this.form.calendar_id
          ) || {}
        );
      }
      return {};
    },
  },
  watch: {
    presentCount: function (count) {
      this.$log.debug("Count was updated", count);
      if (this.studentCount && this.studentCount === count) {
        this.allStudentsPresent = true;
      } else {
        this.allStudentsPresent = false;
      }
    },
    "options.programs": function (value) {
      if (value instanceof Array && value.length > 0) {
        if (
          this.formHasId === true &&
          this.options.programs[0].id !== this.form.program_id
        ) {
          this.options.programs = [
            this.options.programs.find(
              (program) => program.id === this.form.program_id
            ),
          ];
        }
      }
    },
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "attendance/getFormConfig",
      doSaveAttendance: "attendance/save",
      doGetAttendance: "attendance/getOne",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onSave() {
      if (this.formHasCalendarEntryId && this.form.calendar_entry_id) {
        delete this.form.calendar_entry;
      }
      return this.saveFormModel(this.doSaveAttendance).then((attendance) => {
        utils.addToStateData(
          this.selectedProgram.past_calendar_entries,
          attendance.calendar_entry,
          true
        );
        this.$emit("submitted", attendance.attendance_id);
      });
    },
    onGet(id) {
      return this.doGetAttendance(id).then((attendance) => {
        this.hadNullEntryStartDate = false;
        return attendance;
      });
    },
    onToggleMembersDialog() {
      this.dialogs.members = !this.dialogs.members;
    },
    onMarkAll() {
      this.allStudentsPresent = !this.allStudentsPresent;
      this.form.students = this.form.students.map((student) => {
        student.is_present = this.allStudentsPresent;
        return student;
      });
    },
    addCalendar(calendar) {
      const index = this.options.programs.findIndex(
        (p) => p.id === this.form.program_id
      );
      if (index > -1) {
        this.options.programs
          .find((p) => p.id === this.form.program_id)
          .calendars.push(calendar);
      }
      this.setCalendar(calendar.id);
    },
    addMember(member) {
      this.onAddMember(this.form.students, member);
      // this.saveMembers(true);
    },
    removeMember(member) {
      this.onRemoveMember(this.form.students, member);
      // this.saveMembers(true);
    },
    updateCalendarFromEntry(id) {
      this.$log.debug("[Attendance Save]: Updating calendar from entry", id);
      const entry = this.programInstances.find(
        (instance) => instance.id === id
      );
      const calendarId = entry instanceof Object ? entry.calendar_id : null;
      this.$v.form.calendar_id.$reset();
      this.form.calendar_entry.start_date = null;
      this.form.calendar_entry.end_date = null;
      this.form.calendar_entry.start_time = null;
      this.form.calendar_entry.end_time = null;
      this.form.calendar_id = calendarId;
    },
    setCalendar(id) {
      console.log("Date:", Date.now());
      this.$log.debug("[Attendance Save]: Setting calendar", id);
      this.form.calendar_id = id;
      this.form.calendar_entry.start_date = this.$timezone
        .moment(
          this.form.calendar_entry.start_date ||
            this.selectedCalendar.start_date
        )
        .format("YYYY-MM-DD");
      this.form.calendar_entry.start_time =
        this.form.calendar_entry.start_time || this.selectedCalendar.start_time;
      this.form.calendar_entry.end_date = this.$timezone
        .moment(
          this.form.calendar_entry.end_date || this.selectedCalendar.end_date
        )
        .format("YYYY-MM-DD");
      this.form.calendar_entry.end_time =
        this.form.calendar_entry.end_time || this.selectedCalendar.end_time;
      // this.touchCalendar();
    },
    onSetStartDate() {
      this.$refs.startDateMenu.save(this.form.calendar_entry.start_date);
      this.form.calendar_entry.end_date =
        this.form.calendar_entry.end_date ||
        this.form.calendar_entry.start_date;
      this.form.calendar_entry_id = null;
      this.touchCalendar();
    },
    onClearInstanceDate() {
      this.form.calendar_entry.start_date = null;
      this.form.calendar_entry.end_date = null;
      this.form.calendar_entry.start_time = null;
      this.form.calendar_entry.end_time = null;
      this.form.calendar_id = null;
      this.touchCalendar();
    },
    touchCalendar() {
      this.$v.form.calendar_id.$touch();
      this.$v.form.calendar_entry_id.$touch();
      this.$v.form.calendar_entry.$touch();
      this.$v.form.calendar_entry.start_date.$touch();
      this.$v.form.calendar_entry.start_time.$touch();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.v-expansion-panel__header {
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  border-radius: 28px;
  transition: all linear 250ms;
}

.v-expansion-panel__header .v-icon {
  color: $primary-color !important;
}

.v-expansion-panel__header:hover,
.v-expansion-panel__container--active .v-expansion-panel__header {
  background-color: $primary-color;
  color: $white-color;
  padding-left: 24px;
  padding-right: 16px;
}
.v-expansion-panel__container--active .v-expansion-panel__header .v-icon,
.v-expansion-panel__header:hover .v-icon {
  color: inherit !important;
}
</style>
