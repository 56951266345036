<template>
  <VCard
    :class="{
      'is-loading': activity.isLoading
    }"
  >
    <VCardTitle>Details</VCardTitle>
    <VCardText>
      <VCol v-if="hasMessagesOrErrors">
        <Alerts
          :messages="messages"
          :errors="errors"
        />
      </VCol>
      <TextField
        v-model="form.name"
        name="form.name"
        label="Name"
        placeholder="Enter name..."
        :error="$v.form.name.$error"
        @input="$v.form.name.$touch()"
        @blur="$v.$touch()"
      />
      <TextField
        v-model="form.email"
        name="email"
        label="Email"
        placeholder="Leave blank for random email"
        type="email"
        :error="$v.form.email.$error"
        @input="$v.form.email.$touch()"
        @blur="$v.$touch()"
      />
      <Autocomplete
        v-if="options.programs"
        v-model="form.programs"
        multiple
        item-text="title"
        item-value="id"
        append-inner-icon="arrow-down"
        placeholder="(Optional) Select programs..."
        :items="options.programs"
      />
      <VBtn
        color="info"
        large
        block
        :disabled="!isFormReady || isFormInputsDisabled"
        :loading="activity.isFormLoading"
        @click="onSave"
      >
        Save
      </VBtn>
    </VCardText>
  </VCard>
</template>

<script>
import FormMixin from "@/mixins/Form";
import { mapActions } from "vuex";
import { required, minLength, requiredIf } from "vuelidate/lib/validators";
import uuid from "uuid";
export default {
  name: "UserQuickSave",
  components: {},
  mixins: [FormMixin],
  props: {
    role: {
      type: String,
      default: "student"
    },
    programs: {
      type: Array,
      default: () => []
    },
    addExsitingToTeam: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modelType: "User",
      options: {
        programs: []
      },
      saveOnly: [
        "id",
        "name",
        "email",
        "photo_url",
        "password",
        "password_confirmation",
        "timezone",
        "time_format",
        "date_format",
        "profile",
        "city_id",
        "avatar",
        "add_existing_to_team",
        "team_id",
        "role_id",
        "programs",
        "team_user"
      ],
      defaultForm: {
        name: null,
        email: null,
        team_user: {
          role: "student"
        },
        programs: []
      }
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(4)
      },
      email: {
        required: requiredIf(function(form) {
          return !this.$team.email;
        })
      }
    }
  },
  created() {
    this.form.team_user.role = this.role.toLowerCase();
    this.doGetFormConfig().then(result => (this.options = result));
  },
  watch: {
    programs: function(value) {
      if (value instanceof Array) {
        this.form.programs = this.form.programs
          .concat(value.map(p => p.id))
          .unique();
      }
    }
  },
  methods: {
    ...mapActions({
      doSaveUser: "users/save",
      doGetFormConfig: "users/getFormConfig"
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onSave() {
      this.form.team_id = this.$team.id;
      this.form.programs = this.prepareRelationIds(this.form.programs);
      this.form.role_id = this.form.team_user.role;
      const emailDomain = (this.$team.email || "gmail.com").replace(/.*@/, "");
      this.form.email =
        this.form.email || this.form.name.slugify() + "@" + emailDomain;
      this.form.password = uuid.v4();
      this.form.add_existing_to_team = this.addExsitingToTeam;
      // Sending the team messes with the route middleware that checks for teams
      delete this.form.team;
      return this.saveFormModel(this.doSaveUser).then(user => {
        if (user && user.id) {
          this.form = this.cloneObject(this.defaultForm);
          user.attendance = {
            ...(user.attendance || {}),
            ...{
              is_present: true
            }
          };
          this.$emit("saved", user);
        }
      });
    }
  }
};
</script>

