<template>
  <div class="calendar-form">
    <VContainer
      fluid
      grid-list-md
      :class="{
        'is-loading': activity.isLoading
      }"
    >
      <VRow
        row
        wrap
      >
        <VCol v-if="hasMessagesOrErrors">
          <Alerts
            :messages="messages"
            :errors="errors"
          />
        </VCol>
        <VCol cols="12">
          <TextField
            v-model="form.title"
            name="form.title"
            label="Title"
            placeholder="Enter title..."
            :error="$v.form.title.$error"
            @input="$v.form.title.$touch()"
            @blur="$v.$touch()"
          />
        </VCol>
        <VCol cols="12">
          <Swatches
            v-model="form.color"
            inline
          />
        </VCol>
        <VCardActions
          v-if="mode !== 'dialog'"
          class="justify-center"
        >
          <VBtn
            large
            class="wethrive-alt"
            :loading="activity.isFormLoading"
            @click="onSave"
          >
            Save
          </VBtn>
        </VCardActions>
      </VRow>
    </VContainer>
  </div>
</template>
<script>
import Swatches from "vue-swatches";
import { mapActions } from "vuex";
import FormMixin from "@/mixins/CalendarForm";
import { required, minLength, between } from "vuelidate/lib/validators";
import moment from "moment";
import Vue from "vue";

export default {
  name: "QuickSaveLocalCalendarForm",
  components: {
    Swatches
  },
  mixins: [FormMixin],
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
input[type="file"] {
  display: none;
}
</style>

<style lang="scss">
@import "../../styles/variables.scss";

.v-btn.dashed {
  border: 1px solid #909295;
  border-style: dashed;
}

.v-btn.dashed {
  color: $font-light-color;
}
</style>